import {
  UserStatus,
  FuelCategoryEnum,
  ProductCodeEnum,
  ShiftStatus,
  CustomerType,
  PaymentMethod,
  EmployeePosition,
  PromoStatusEnum,
  PromoTypeEnum,
  PromoCancelReasonEnum,
  StationPositionEnum,
  ProductCategory,
} from "enums";
import StationStatusEnum from "enums/station-status";
import StationSubscriptionEnum from "enums/station-subscription";
import { locale } from "localization/en";

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyShiftStatus = (type) => {
  return (
    {
      [ShiftStatus.Started]: locale.started,
      [ShiftStatus.Ended]: locale.ended,
      [ShiftStatus.Closed]: locale.closed,
    }[type] || ""
  );
};

export const prettifyCustomerType = (type) => {
  return (
    {
      [CustomerType.Transient]: locale.transient,
      [CustomerType.KeyAccount]: locale.keyAccount,
    }[type] || ""
  );
};

export const prettifyPaymentMethod = (type) => {
  return (
    {
      [PaymentMethod.Card]: "Card",
      [PaymentMethod.Cash]: "Cash",
      [PaymentMethod.Check]: "Check",
      [PaymentMethod.SplitPayment]: "Split Payment",
      [PaymentMethod.KeyAccount]: "Key Account",
      [PaymentMethod.ShopeePay]: "ShopeePay",
      [PaymentMethod.PLC]: "PLC",
      [PaymentMethod.PLB]: "PLB",
      [PaymentMethod.LOCQPay]: "LOCQPay",
      [PaymentMethod.Other]: "Other",
      [PaymentMethod.Maya]: "Maya",
      [PaymentMethod.GiftCard]: "Gift Card",
      [PaymentMethod.VIP]: "VIP Redemption",
      [PaymentMethod.GCash]: "GCash",
    }[type] || "Others"
  );
};

export const prettifyFuelCategory = (type) => {
  return (
    {
      [FuelCategoryEnum.GASOLINE]: "Gasoline",
      [FuelCategoryEnum.DIESEL]: "Diesel",
      [FuelCategoryEnum.KEROSENE]: "Kerosene",
      [FuelCategoryEnum.LPG]: "LPG",
    }[type] || ""
  );
};

export const prettifyProductCode = (type) => {
  return (
    {
      [ProductCodeEnum.DIESEL]: "Diesel",
      [ProductCodeEnum.GAS91]: "Gas 91",
      [ProductCodeEnum.GAS95]: "Gas 95",
      [ProductCodeEnum.GAS97]: "Gas 97",
      [ProductCodeEnum.LUBRICANTS]: "Lubricants",
      [ProductCodeEnum.LPG]: "LPG",
    }[type] || ""
  );
};

export const prettifyProductType = (type) => {
  return (
    {
      fuel: "Fuel",
      lubes: "Lubricants",
      lpg: "LPG",
    }[type] || type
  );
};

export const prettifyProductCategory = (type) => {
  return (
    {
      [ProductCategory.WETSTOCK]: "Wet Stock",
      [ProductCategory.DRYSTOCK]: "Dry Stock",
    }[type] || type
  );
};

export const prettifyEmployeePosition = (position) => {
  return (
    {
      [EmployeePosition.STATION_HEAD]: "Station Head",
      [EmployeePosition.STATION_CREW]: "Station Crew",
      [EmployeePosition.CASHIER]: "Cashier",
      [EmployeePosition.SECURITY_GUARD]: "Security Guard",
      [EmployeePosition.SERVICE_ADVISOR]: "Service Advisor",
      [EmployeePosition.TECHNICIAN]: "Technician",
      [EmployeePosition.CREW]: "Employee",
    }[position] || ""
  );
};

export const prettifyPromoType = (promoType) => {
  return (
    {
      [PromoTypeEnum.DiscountPerLiter]: locale.discountPerLiter,
      [PromoTypeEnum.DiscountPerTransaction]: locale.discountPerTransaction,
      [PromoTypeEnum.DiscountPerUnit]: locale.discountPerUnit,
      [PromoTypeEnum.PercentDiscount]: locale.percentDiscount,
    }[promoType] || ""
  );
};

export const prettifyPromoStatus = (promoStatus) => {
  return (
    {
      [PromoStatusEnum.Upcoming]: locale.upcoming,
      [PromoStatusEnum.Ongoing]: locale.ongoing,
      [PromoStatusEnum.Ended]: locale.ended,
      [PromoStatusEnum.Cancelled]: locale.cancelled,
    }[promoStatus] || ""
  );
};

export const prettifyStationStatus = (status) => {
  return (
    {
      [StationStatusEnum.PENDING]: locale.pending,
      [StationStatusEnum.ACTIVE]: locale.active,
      [StationStatusEnum.DEACTIVATED]: locale.deactivated,
      [StationStatusEnum.MANUAL_MODE]: locale.manualMode,
    }[status] || ""
  );
};

export const prettifyStationSubscription = (subscription) => {
  return (
    {
      [StationSubscriptionEnum.IMS]: "IMS",
      [StationSubscriptionEnum.POS]: "POS",
    }[subscription] || ""
  );
};

export const prettifyCancelPromoReason = (reason) => {
  return (
    {
      [PromoCancelReasonEnum.INCORRECT]: locale.incorrectDetails,
      [PromoCancelReasonEnum.DUPLICATE]: locale.duplicatePromo,
      [PromoCancelReasonEnum.OTHERS]: locale.others,
    }[reason] || ""
  );
};

export const prettifyStationPosition = (position) => {
  return (
    {
      [StationPositionEnum.CASHIER]: locale.cashier,
      [StationPositionEnum.SERVICE_ADVISOR]: locale.serviceAdvisor,
      [StationPositionEnum.STATION_HEAD]: locale.stationHead,
      [StationPositionEnum.TECHNICIAN]: locale.technician,
    }[position] || ""
  );
};
