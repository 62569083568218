import { BackLink, Button, Image, Intro, Title } from "components/commons";
import { FormProvider } from "contexts/form-context";
import { FormMode, Path } from "enums";
import locale from "localization";
import AddEditFormProduct from "./add-edit-form-product";
import React, { useMemo, useState } from "react";
import { initialStateProduct } from "./add-edit-form-product-state";
import useStations from "hooks/useStations";
import { useAlert, useApi, useModal, useMount, useRouter } from "hooks";
import { ConfirmModal } from "components/modals";
import { GuySavingWEBP } from "images";
import styles from "../product-details.module.scss";
import { modalStyle } from "modules/promos/promo-details/add-edit-promo-form";
import useLeavePageBlocker from "hooks/useLeavePageBlocker";
import { createProductApi, getProductByIdApi, updateProductApi, uploadFileApi } from "apis";
import { Skeleton } from "@material-ui/lab";
import { stringToDecimal } from "utils/text.utils";
import EditIcon from "@material-ui/icons/Edit";

const ProductDetailsModule = () => {
  const { fetch: getAllStations, loading: isLoadingAllStations } = useStations();
  const { addAlert } = useAlert();
  const confirmModal = useModal();
  const leaveModal = useModal();
  const [isLeavePageBlock, setIsLeavePageBlock] = useState(true);

  const [form, setForm] = useState(null);
  const router = useRouter();

  const formMode = router.query?.mode || FormMode.Add;
  const isAdd = formMode === FormMode.Add;

  const productId = router.query?.id;

  const { request: saveProduct, loading: isSaving } = useApi({
    api: isAdd ? createProductApi : updateProductApi,
    key: "save-product",
    modalError: true,
    handleOwnError: true,
  });

  const {
    request: getProductById,
    loading: isGetting,
    result: productDetails,
  } = useApi({
    api: getProductByIdApi,
  });

  const { request: uploadFile, loading: isUploading } = useApi({
    api: uploadFileApi,
  });

  const initialState = useMemo(() => {
    return initialStateProduct(productDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMode, productDetails]);

  useMount(async () => {
    if (!isAdd) {
      await Promise.all([getProductById({ id: productId }), getAllStations()]);
    } else {
      await Promise.all([getAllStations()]);
    }
  });

  const introProps = useMemo(() => {
    const title =
      formMode === FormMode.Edit
        ? locale.editProduct
        : formMode === FormMode.Add
        ? locale.addProduct
        : locale.viewProduct;

    const actionText =
      formMode === FormMode.Edit
        ? locale.saveChanges
        : formMode === FormMode.View
        ? locale.edit
        : "";

    return {
      actionDisabled:
        (formMode === FormMode.Edit && !form?.isFormSubmittable) ||
        isSaving ||
        isLoadingAllStations,
      title,
      actionText,
      actionLoading: isSaving || isLoadingAllStations,
      actionStartIcon: formMode === FormMode.View && <EditIcon />,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMode, productId, form?.isFormSubmittable, isSaving, isLoadingAllStations]);

  const handleOnSubmit = () => {
    confirmModal.show({
      title: !isAdd ? locale.saveChanges + "?" : undefined,
      content: !isAdd ? locale.confirmChanges : undefined,
      primary: {
        onClick: handleSaving,
        text: isAdd ? locale.yes : locale.saveChanges,
      },
      secondary: {
        text: isAdd ? locale.no : locale.continueEditing,
      },
      ...modalStyle,
    });
  };

  const handleSaving = async () => {
    const formValues = form.getFormValues();

    setIsLeavePageBlock(true);

    let uploadedLink = undefined;
    if (formValues.image instanceof File) {
      const formData = new FormData();
      formData.append("file", formValues.image);

      const resUpload = await uploadFile({ body: formData });
      if (!resUpload || !resUpload?.fileUrl) {
        return addAlert({
          title: locale.imageUploadFailed,
          subtitle: locale.imageErrorContent,
          type: "error",
          interval: 10000,
        });
      }

      uploadedLink = resUpload?.fileUrl;
    }

    const body = {
      sku: isAdd ? formValues.sku : undefined,
      name: formValues.name,
      variant: formValues?.variant || "test variant",
      amount: formValues?.defaultPrice
        ? String(stringToDecimal(formValues?.defaultPrice))
        : undefined,
      categoryId: formValues?.category ? +formValues?.category : undefined,
      type: formValues.type,
      unit: formValues.type === "fuel" ? "liters" : "pieces",
      stationCodes: formValues.businessLocations,
      image: uploadedLink,
    };

    try {
      if (isAdd) {
        const res = await saveProduct({ body: body }, () => {}, { reqError: true });
        const productSkuError = res?.error?.data?.errorCode === "P10000" && locale.skuAlreadyExist;
        if (res?.error) {
          confirmModal.close();
          return addAlert({
            title: "Something wrong!",
            subtitle: productSkuError || res?.error?.data?.message,
            type: "error",
            interval: 10000,
          });
        }

        addAlert({
          title: locale.productSaved,
          subtitle: locale.successfullySavedProduct,
          type: "success",
          interval: 10000,
        });
        return router.push(Path.Products);
      }

      const res = await saveProduct({ id: productId, body: body }, () => {}, {
        reqError: true,
      });
      if (res?.error)
        return addAlert({
          title: "Something wrong!",
          subtitle: res?.error?.data?.message,
          type: "error",
          interval: 10000,
        });

      setIsLeavePageBlock(false);
      confirmModal.show({
        ...modalStyle,
        title: `${locale.success}`,
        content: locale.changesSuccess,
        primary: {
          onClick: () => {
            router.push(Path.Products);
          },
        },
        close: () => {
          router.push(Path.Products);
        },
      });
    } catch (error) {
      confirmModal.close();
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use 'auto' for instant scroll
      });
    }
  };

  useLeavePageBlocker(
    (nextPathname) => {
      if (formMode === FormMode.Edit) {
        leaveModal.show({
          title: locale.leavePageQuestion,
          content: locale.leavePageQuestionContent,
          secondary: {
            text: locale.continueEditing,
          },
          primary: {
            text: locale.leavePage,
            onClick: () => {
              window.location.href = nextPathname;
            },
          },
          ...modalStyle,
          contentStyle: styles.contentLeavePageModal,
        });
      }
    },
    [],
    { isListen: formMode === FormMode.Edit && isLeavePageBlock }
  );

  return (
    <>
      <ConfirmModal
        {...confirmModal}
        primary={{ ...confirmModal?.primary }}
        centerActions={formMode === FormMode.Add}
        closable={!isSaving || !isUploading}
        loading={isSaving || isUploading}
      >
        {formMode === FormMode.Add && (
          <div className={styles.saveProductModalContent}>
            <Image src={GuySavingWEBP} style={{ width: "300px" }} />
            <Title
              subText={locale.confirmSaveProduct}
              style={{ color: "#000", textAlign: "center" }}
            >
              {locale.saveProduct}
            </Title>
          </div>
        )}
      </ConfirmModal>
      <ConfirmModal {...leaveModal} />
      <BackLink text={locale.products} path={Path.Products} />
      <Intro
        {...introProps}
        actionClassName={styles.editSaveBtn}
        actionOnClick={() =>
          formMode === FormMode.View
            ? router.push(Path.EditProduct(productId, FormMode.Edit))
            : handleOnSubmit()
        }
      />
      <FormProvider initialState={initialState} onChange={(form) => setForm(form)}>
        {!isGetting && <AddEditFormProduct />}
        {isGetting && (
          <>
            <div style={{ width: "500px", display: "flex", flexDirection: "column" }}>
              <Skeleton height={"50px"} />
              <Skeleton height={"50px"} />
              <Skeleton height={"50px"} />
              <Skeleton height={"50px"} />
              <Skeleton height={"50px"} />
              <Skeleton height={"50px"} />
              <Skeleton height={"50px"} />
            </div>
          </>
        )}
      </FormProvider>
      <br />
      {isAdd && (
        <Button
          style={{ width: "110px" }}
          primary
          onClick={handleOnSubmit}
          disabled={!form?.isFormSubmittable}
        >
          {locale.save}
        </Button>
      )}
    </>
  );
};

export default React.memo(ProductDetailsModule);
