import { PumpBrandsEnum } from "enums";
import {
  today1159PMTime,
  today12AMtime,
} from "modules/promos/promo-details/add-edit-promo-form-state";
import { timeStringToDate } from "utils";
import Validation from "utils/validation.utils";

export const SHORT_TEXT_MAX_LEN = 75;
export const MEDIUM_TEXT_MAX_LEN = 150;
export const LONG_TEXT_MAX_LEN = 225;

const initialStationFormState = ({ stationDetails }) => {
  const pumpBrands =
    stationDetails?.pumpBrands?.split(",")?.map((val) => ({ label: val, value: val })) || [];
  return {
    name: {
      name: "name",
      value: stationDetails?.name || "",
      type: "text",
      required: true,
      maxLength: SHORT_TEXT_MAX_LEN,
      minLength: 0,
      validations: [Validation.required()],
      placeholder: "e.g SEAOIL Calumpit - Bulacan",
      helperText: "Format: [Brand Name] - [Area/Village/Municipality] - [Province Name]",
    },
    code: {
      name: "code",
      value: stationDetails?.stationCode || "",
      type: "text",
      required: true,
      maxLength: 4,
      validations: [Validation.required(), Validation.numeric()],
      placeholder: "0000",
    },
    subscription: {
      name: "subscription",
      value: stationDetails?.subscription || "",
      required: true,
      validations: [Validation.required()],
      placeholder: "Select One",
    },
    type: {
      name: "type",
      value: stationDetails?.type || "",
      required: true,
      validations: [Validation.required()],
      placeholder: "Select One",
    },
    taxPayerName: {
      name: "taxPayerName",
      value: stationDetails?.registeredBusinessName || "",
      type: "text",
      required: true,
      maxLength: SHORT_TEXT_MAX_LEN,
      minLength: 0,
      validations: [Validation.required()],
      placeholder: "Type here",
    },
    taxPayerRegisteredAddress: {
      name: "taxPayerRegisteredAddress",
      value: stationDetails?.businessAddress || "",
      type: "text",
      required: true,
      maxLength: LONG_TEXT_MAX_LEN,
      minLength: 0,
      validations: [Validation.required()],
      placeholder: "Type here",
    },
    birTin: {
      name: "birTin",
      value: stationDetails?.vatRegTin || "",
      type: "text",
      required: true,
      maxLength: SHORT_TEXT_MAX_LEN,
      minLength: 0,
      validations: [Validation.required()],
      placeholder: "000-000-000-00000",
    },
    openingTime: {
      name: "openingTime",
      required: true,
      value: stationDetails?.opensAt ? timeStringToDate(stationDetails?.opensAt) : today12AMtime(),
      validations: [Validation.required()],
      placeholder: "Set time",
    },
    closingTime: {
      name: "closingTime",
      required: true,
      value: stationDetails?.closesAt
        ? timeStringToDate(stationDetails?.closesAt)
        : today1159PMTime(),
      validations: [Validation.required()],
      placeholder: "Set time",
    },
    lat: {
      name: "lat",
      value: stationDetails?.latitude || "",
      required: true,
      maxLength: 15,
      validations: [
        Validation.required(),
        // Validation.numeric(),
        Validation.maxlength(15),
      ],
      placeholder: "e.g.14.34098",
      helperText:
        "Please input at least 5 decimal places e.g.14.34098 for a more accurate display of location pin in the app",
    },
    long: {
      name: "long",
      value: stationDetails?.longitude || "",
      required: true,
      maxLength: 15,
      validations: [
        Validation.required(),
        // Validation.numeric(),
        Validation.maxlength(15),
      ],
      placeholder: "e.g.120.20032",
      helperText:
        "Please input at least 5 decimal places e.g.120.20032 for a more accurate display of location pin in the app",
    },
    pumpBrands: {
      name: "pumpBrands",
      value: pumpBrands,
      required: true,
      validations: [Validation.required(), Validation.requiredArray({ notEmpty: true })],
      placeholder: "Select",
      isAll: Object.values(PumpBrandsEnum).length === pumpBrands?.length,
    },
    safeDropThreshold: {
      name: "safeDropThreshold",
      value: isNaN(+stationDetails?.thresholdSafeDrop)
        ? ""
        : String(stationDetails?.thresholdSafeDrop),
      decimalPlaces: 2,
      isPriceValue: true,
      required: true,
      max: 20000,
      min: 0,
      validations: [Validation.required()],
      placeholder: "0.00",
    },
    maxTransactionStacking: {
      name: "maxTransactionStacking",
      value: stationDetails?.maxTransactionStacking || "2",
      required: true,
      max: 5,
      min: 1,
      validations: [Validation.required(), Validation.min(1), Validation.max(5)],
    },
    serialNumber: {
      name: "serialNumber",
      value: stationDetails?.serialNumber || "",
      type: "text",
      required: true,
      maxLength: SHORT_TEXT_MAX_LEN,
      minLength: 0,
      validations: [Validation.required()],
      placeholder: "0000000",
    },
    machineIdentificationNo: {
      name: "machineIdentificationNo",
      value: stationDetails?.machineIdentificationNumber || "",
      type: "text",
      required: true,
      maxLength: SHORT_TEXT_MAX_LEN,
      minLength: 0,
      validations: [Validation.required()],
      placeholder: "00000000000000000",
    },
    ptuNumber: {
      name: "ptuNumber",
      value: stationDetails?.ptuNumber || "",
      type: "text",
      required: true,
      maxLength: SHORT_TEXT_MAX_LEN,
      minLength: 0,
      validations: [Validation.required()],
      placeholder: "Type here",
    },
    ugts: {
      name: "ugts",
      value:
        stationDetails?.stationUgts?.length > 0
          ? stationDetails?.stationUgts?.map(({ productCode, liter }) => ({
              product: productCode,
              liters: liter,
            }))
          : [
              {
                product: "",
                liters: "",
              },
            ],
      required: true,
      validations: [Validation.required(), Validation.requiredArray({ notEmpty: true })],
    },
    users: {
      name: "users",
      value:
        stationDetails?.users?.length > 0
          ? stationDetails?.users?.map(
              ({ username, firstName, lastName, password, role, userId }) => ({
                username,
                firstName: firstName,
                lastName: lastName,
                password: password,
                position: role,
              })
            )
          : [],
      required: true,
      validations: [Validation.required(), Validation.requiredArray({ notEmpty: true })],
    },
    // keyAccounts: {
    //     name: "keyAccounts",
    //     value: [],
    //     required: true,
    //     validations: [Validation.required(), Validation.requiredArray({ notEmpty: true })]
    // },
    dryStocks: {
      name: "dryStocks",
      value: stationDetails?.products || [],
    },
    promos: {
      name: "promos",
      value: stationDetails?.promos || [],
    },
  };
};

export default initialStationFormState;
