const AccessAuthorization = "/access-authorization";
const Promos = "/promos";
const Stations = "/stations";
const Products = "/products";

const Path = {
  Slash: "/",
  Auth: "/auth",
  AccessAuthorization: AccessAuthorization,
  UserAccess: `${AccessAuthorization}/user-access`,
  Stations: Stations,
  AddStation: `${Stations}/add`,
  EditStation: (id, mode) => `${Stations}/details/${id}/${mode}`,
  ViewStation: (id, mode) => `${Stations}/details/${id}/${mode}`,
  StationDetails: `${Stations}/details/:id/:mode`,
  PromosList: Promos,
  AddPromo: `${Promos}/add`,
  PromoDetails: `${Promos}/details/:id/:mode`,
  Products: `${Products}`,
  AddProduct: `${Products}/add`,
  ProductDetails: `${Products}/details/:id/:mode`,
  EditProduct: (id, mode) => `${Products}/details/${id}/${mode}`,
  ViewProduct: (id, mode) => `${Products}/details/${id}/${mode}`,
};

export default Path;
