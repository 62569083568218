import React from "react";
import { ErrorOutline, ReportProblemOutlined, CheckCircleOutline } from "@material-ui/icons";
import classNames from "classnames";
import styles from "./highlight.module.scss";
import Text from "../text/text";

const Highlight = ({ title, subtitle, className, children, error, warning, success }) => {
  return (
    <div
      className={classNames(
        className, // This allows `enter` and `exit` to apply
        styles.highlight,
        { [styles.error]: error },
        { [styles.warning]: warning },
        { [styles.success]: success }
      )}
    >
      {error && <ErrorOutline className={styles.errorIcon} />}
      {warning && <ReportProblemOutlined className={styles.warningIcon} />}
      {success && <CheckCircleOutline className={styles.successIcon} />}
      {(title || subtitle) && (
        <div className={styles.content}>
          <Text className={styles.title}>{title}</Text>
          <Text subtitle className={styles.subtitle}>
            {subtitle}
          </Text>
        </div>
      )}
      {children}
    </div>
  );
};

export default Highlight;
