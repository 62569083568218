import {
  Autocomplete,
  Field,
  SelectFile,
  Text,
  TextField,
  TimePickerRange,
} from "components/commons";
import { useFormContext } from "contexts/form-context";
import { FormMode } from "enums";
import { isArray } from "lodash";
import { formatAmount, formatNumber } from "utils";
import styles from "../product-details.module.scss";
import locale from "localization";
import { InputAmount } from "components/field";
import ParticipatingStationsInput from "modules/promos/promo-details/add-edit-components/participating-stations-input";
import { useContext, useEffect, useMemo } from "react";
import Validation from "utils/validation.utils";
import { prettifyProductType } from "utils/pretty.utils";
import { useApi, useMount } from "hooks";
import { getCategoryApi } from "apis";
import { AppContext } from "contexts";

export const RenderInput = ({ Component, prettify, ...props }) => {
  const { formMode } = useFormContext();

  if (Component.name !== TimePickerRange.name && formMode === FormMode.View) {
    if (props?.isPriceValue && props?.decimalPlaces) {
      return <Text color={"#000"}>₱ {formatNumber(props?.value, props.decimalPlaces)}</Text>;
    }

    return (
      <Text color={"#000"}>
        {props?.isPriceValue
          ? formatAmount(props.value)
          : prettify
          ? prettify?.(props.value)
          : isArray(props.value)
          ? props.value.join(", ")
          : props.value}
      </Text>
    );
  }

  return <Component {...props} />;
};

const AddEditFormProduct = () => {
  const { apisState } = useContext(AppContext);
  const form = useFormContext();
  const { formMode, fields, modifyField, modifyForm, initialState } = form;

  const type = fields?.type?.value;

  const {
    request: getCategory,
    result,
    loading: isGettingCategories,
  } = useApi({
    api: getCategoryApi,
    params: {
      perPage: 1000,
      page: 1,
    },
  });

  const categoriesOptions = useMemo(() => {
    const acceptedIds = [1, 2, 3, 4, 5]; // currently follow in the PRD.
    return result?.categories
      ?.filter((category) => acceptedIds.includes(category.categoryId))
      .map((category) => ({
        label: category.name,
        value: String(category.categoryId),
      }));
  }, [result]);

  const productSkuError =
    apisState?.["save-product"]?.errorData?.data?.errorCode === "P10000" && locale.skuAlreadyExist;

  useMount(async () => {
    await getCategory();
  });

  useEffect(() => {
    if (!type) return;

    modifyForm((fields) => {
      let category = {
        ...initialState.category,
        required: false,
        value: fields.category.value,
      };
      let variant = {
        ...initialState.variant,
        value: fields.variant.value,
      };
      let defaultPrice = {
        ...initialState.defaultPrice,
        required: false,
        value: fields.defaultPrice.value,
      };

      const isEdit = formMode === FormMode.Edit;

      if (type === "fuel") {
        variant = {
          ...variant,
          value: isEdit ? variant.value : "",
        };
        defaultPrice = {
          ...defaultPrice,
          value: isEdit ? defaultPrice.value : "",
        };
      } else {
        variant = {
          ...variant,
          validations: [Validation.required()],
        };
        defaultPrice = {
          ...defaultPrice,
          required: true,
          validations: [Validation.required()],
        };
      }

      if (type === "lubes") {
        category = {
          ...category,
          required: true,
          validations: [Validation.required()],
        };
      } else {
        category = {
          ...category,
          value: isEdit ? category.value : "",
          required: false,
          validations: [],
        };
      }

      return {
        ...fields,
        category,
        variant,
        defaultPrice,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, initialState, formMode]);

  return (
    <div className={styles.container}>
      <Field
        label={locale.productType}
        labelPosition={"left-aligned"}
        labelClassName={styles.label}
        childrenClassName={styles.fieldContent}
      >
        <RenderInput
          {...fields?.type}
          onChange={modifyField}
          Component={Autocomplete}
          options={[
            {
              label: locale.fuel,
              value: "fuel",
            },
            {
              label: locale.lubricants,
              value: "lubes",
            },
            {
              label: locale.lpg,
              value: "lpg",
            },
          ]}
          disabled={formMode === FormMode.Edit}
          prettify={prettifyProductType}
        />
      </Field>
      {fields.type?.value && (
        <>
          {fields?.type?.value === "lubes" && !isGettingCategories && (
            <Field
              label={locale.productCategory}
              labelPosition={"left-aligned"}
              labelClassName={styles.label}
              childrenClassName={styles.fieldContent}
            >
              <RenderInput
                {...fields?.category}
                value={
                  formMode === FormMode.View
                    ? categoriesOptions?.find(
                        (option) => String(option?.value) === String(fields?.category?.value)
                      )?.label || "--"
                    : fields?.category?.value
                }
                onChange={modifyField}
                Component={Autocomplete}
                options={categoriesOptions}
              />
            </Field>
          )}
          <Field
            label={locale.productImage}
            labelPosition={"left-aligned"}
            labelClassName={styles.label}
            childrenClassName={formMode !== FormMode.View && styles.fieldContent}
          >
            <SelectFile
              {...fields?.image}
              onChange={modifyField}
              type="image"
              isView={formMode === FormMode.View}
            />
          </Field>
          <Field
            label={locale.productName}
            labelPosition={"left-aligned"}
            labelClassName={styles.label}
            childrenClassName={styles.fieldContent}
          >
            <RenderInput {...fields?.name} onChange={modifyField} Component={TextField} />
          </Field>
          {fields?.type?.value !== "fuel" && (
            <Field
              label={locale.productVariant}
              labelPosition={"left-aligned"}
              labelClassName={styles.label}
              childrenClassName={styles.fieldContent}
            >
              <RenderInput {...fields?.variant} onChange={modifyField} Component={TextField} />
            </Field>
          )}
          <Field
            label={locale.sku}
            labelPosition={"left-aligned"}
            labelClassName={styles.label}
            childrenClassName={styles.fieldContent}
          >
            <RenderInput
              {...fields?.sku}
              disabled={formMode === FormMode.Edit}
              inputDisabled={formMode === FormMode.Edit}
              onChange={modifyField}
              helperText={productSkuError}
              error={fields?.sku?.error || !!productSkuError}
              Component={TextField}
              title={formMode === FormMode.Edit ? fields?.sku?.value : ""}
            />
          </Field>
          <Field
            label={locale.unitOfMeasurement}
            labelPosition={"left-aligned"}
            labelClassName={styles.label}
            childrenClassName={styles.fieldContent}
          >
            <Text
              className={styles.label}
              style={
                formMode !== FormMode.View && {
                  margin: "1em 0px",
                }
              }
            >
              {fields?.type?.value === "fuel" ? "Liters" : "Pieces"}
            </Text>
          </Field>
          <Field
            label={locale.quantityDecimalPlaces}
            labelPosition={"left-aligned"}
            labelClassName={styles.label}
            childrenClassName={styles.fieldContent}
          >
            <Text className={styles.label}>{fields?.type?.value === "fuel" ? "3" : "0"}</Text>
          </Field>

          {fields?.type?.value !== "fuel" && (
            <Field
              label={locale.defaultPrice}
              labelPosition={"left-aligned"}
              labelClassName={styles.label}
              childrenClassName={styles.shortContent}
            >
              <RenderInput
                {...fields?.defaultPrice}
                onChange={modifyField}
                Component={InputAmount}
              />
            </Field>
          )}
          <Field
            label={locale.businessLocations}
            labelPosition={"left-aligned"}
            labelClassName={styles.label}
          >
            <ParticipatingStationsInput
              {...fields?.businessLocations}
              onChange={modifyField}
              hideTitle
              isView={formMode === FormMode.View}
            />
          </Field>
        </>
      )}
    </div>
  );
};

export default AddEditFormProduct;
