import { useCallback, useContext } from "react";
import useApi from "./useApi";
import { AppContext } from "contexts";
import { getStations } from "apis";

const useStations = () => {
  const { allStations, setAllStations, apisState } = useContext(AppContext);

  const key = "all-stations";
  const getAllStations = useApi({
    api: getStations,
    key: key,
  });

  const fetch = useCallback(
    async () => {
      const res = await getAllStations?.request(
        {
          page: 1,
          perPage: 1000,
        },
        () => {},
        { useLoader: true }
      );

      const stations = res.stations;
      setAllStations(stations);

      return stations;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetch,
    allStations,
    loading: !!apisState?.[key]?.loading,
  };
};

export default useStations;
