import { AppContext } from "contexts";
import { Path } from "enums";
import { useContext, useEffect, useRef, useState } from "react";
import { redirectTo } from "utils";

const useApi = ({
  api,
  params = {},
  handleOwnError = false,
  modalError = true,
  pageError,
  key,
}) => {
  const { errorModal, setPageError, setErrorStatus, setApisState } = useContext(AppContext);
  const [loading, setLoading] = useState(undefined);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(undefined);
  const [called, setCalled] = useState(false);
  const [errorData, setErrorData] = useState(null);

  // fix: unmount error
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const showError = (err, retry) => {
    if (pageError) {
      setPageError(true);
      setErrorStatus(err.status);
    } else {
      if (modalError) {
        errorModal.show({
          onClick: async () => {
            errorModal.close();
            await retry();
          },
          error: err,
        });
      }
    }
    throw err;
  };

  const request = async (p, retry = () => {}, options = { useLoader: true, reqError: false }) => {
    setCalled(false);
    const { useLoader = true } = options || {};
    if (useLoader && isMounted.current) {
      setLoading(true);
    }

    setError(false);
    const obj = { ...params, ...p };
    const cleanedObj = {};

    for (const [key, value] of Object.entries(obj)) {
      if (value !== null && value !== "") {
        cleanedObj[key] = value;
      }
    }

    try {
      const res = await api(cleanedObj);

      if (isMounted.current) {
        setResult(res);
        setLoading(false);
        setCalled(true);
      }

      return res;
    } catch (err) {
      console.error("Error", err);
      // token is invalidated

      err.showError = () => showError(err, retry);

      if (isMounted.current) {
        setError(true);
        setErrorStatus(err.status);
        setLoading(false);
        setCalled(true);
        setErrorData(err);
      }

      // timeout
      if (!err.status) {
        return showError(err);
      }

      if (err.status === 401) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        redirectTo(Path.Auth);
        return;
      }

      if (options?.reqError) {
        return { error: err, result: null };
      }

      if (handleOwnError) {
        if (typeof handleOwnError === "boolean") {
          throw err;
        } else if (typeof handleOwnError === "object") {
          if (handleOwnError.hasOwnProperty(err.error)) {
            throw err;
          } else {
            return showError(err);
          }
        }
      } else {
        return showError(err);
      }
    }
  };

  useEffect(() => {
    if (key) {
      setApisState((apis) => ({
        ...apis,
        [key]: {
          loading,
          error,
          called,
          errorData,
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, called, errorData, api, key]);

  return { request, loading, result, error, called, key, errorData };
};

export default useApi;
