import { BrowserRouter as Router, Switch } from "react-router-dom";
import Path from "./enums/path";
import AuthPage from "./pages/auth.page";
import AuthorizedPage from "./pages/authorized.page";
import AuthRoute from "./routes/auth.route";
import AuthorizedRoute from "./routes/authorized.route";
import { AppContext } from "contexts";
import { ErrorModal } from "components/modals";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useModal } from "hooks";
import { Image } from "components/commons";
import { CautionSign } from "images";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider } from "@material-ui/core/styles";
import ErrorPage from "pages/error.page";
import { CarCrash } from "images";
import UnauthorizedPage from "pages/unauthorized.page";
import firebase from "firebase/compat/app";
import env from "environments/env";
import useBeforeMount from "hooks/useBeforeMount";
import FeatureFlagPage from "pages/featureflag.page";
import AppLoader from "components/commons/app-loader/app-loader";

const useAppState = () => {
  const [appState, setAppState] = useState({ loading: false });
  const errorModal = useModal();
  const [pageError, setPageError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);

  const [allProducts, setAllProducts] = useState([]);
  const [allStations, setAllStations] = useState([]);
  const [stateForm, setStateForm] = useState(null);
  const [allPromos, setAllPromos] = useState([]);
  const [apisState, setApisState] = useState({});
  const [alerts, setAlerts] = useState([]);

  const toggleLoading = useCallback((loading) => {
    setAppState((state) => {
      return { ...state, loading };
    });
  }, []);

  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    toggleLoading,
    errorModal,
    appState,
    pageError,
    setPageError,
    errorStatus,
    setErrorStatus,
    allProducts,
    setAllProducts,
    allStations,
    setAllStations,
    stateForm,
    setStateForm,
    allPromos,
    setAllPromos,
    apisState,
    setApisState,
    alerts,
    setAlerts,
    isMounted,
  };
};

function App() {
  const appState = useAppState();
  const [initializing, setInitializing] = useState(true);

  useBeforeMount(async () => {
    const initializeFirebaseApp = async (config) => {
      return new Promise((resolve, reject) => {
        try {
          firebase.initializeApp(config);
          setTimeout(() => resolve(), 1000);
        } catch (err) {
          reject(err);
        }
      });
    };

    const initFirebase = async () => {
      try {
        await initializeFirebaseApp(env.FIREBASE_CONFIG);
      } catch (err) {
      } finally {
        setInitializing(false);
      }
    };

    initFirebase();
  });

  return (
    <FeatureFlagPage>
      {initializing ? (
        <AppLoader />
      ) : (
        <AppContext.Provider value={appState}>
          <StylesProvider injectFirst>
            <ErrorModal {...appState.errorModal} />
            <CssBaseline />
            <div className="App">
              {appState.pageError ? (
                appState.errorStatus === 403 || appState.errorStatus === 401 ? (
                  <UnauthorizedPage />
                ) : (
                  <ErrorPage />
                )
              ) : (
                <Router>
                  <Switch>
                    <AuthRoute path={Path.Auth} component={AuthPage} />
                    <AuthorizedRoute path={Path.Slash} component={AuthorizedPage} />
                  </Switch>
                </Router>
              )}
            </div>
          </StylesProvider>
          <div className="render-offline-images">
            <Image src={CautionSign} />
            <Image src={CarCrash} />
          </div>
        </AppContext.Provider>
      )}
    </FeatureFlagPage>
  );
}

export default App;
