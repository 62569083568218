import React, { useEffect, useMemo } from "react";
import locale from "localization/en";
import useProducts from "hooks/useProducts";
import { useModal } from "hooks";
import styles from "../../station-details.module.scss";
import { SelectProductsModal } from "components/modals";
import { useCallback, useState } from "react";
import { Button } from "components/commons";
import ViewDryStocksComponent from "../view-components/view-dry-stocks";
import { useFormContext } from "contexts/form-context";
import CheckIcon from "@material-ui/icons/Check";
import { find } from "lodash";
import { stringToDecimal } from "utils/text.utils";
import { RenderElementByFormMode } from "../../station-details.module";
import { FormMode } from "enums";

const InputDryStocksComponent = () => {
  const { modifyField, fields, formMode, setSubFormsSubmittableState } = useFormContext();
  const { allProducts } = useProducts();
  const selectProductModal = useModal();
  const [selected, setSelected] = useState([]);

  const dryStocksValue = useMemo(() => fields.dryStocks.value, [fields.dryStocks]);
  useEffect(() => {
    setSelected(dryStocksValue);
  }, [dryStocksValue]);

  const validateOverridePrices = (updatedValues) => {
    const productsOverridePrices = updatedValues?.map(({ overridePrice }) => overridePrice);
    const isValidPrices = productsOverridePrices.every(
      (price) => stringToDecimal(price) > 0.01 && stringToDecimal(price) <= 999999.99
    );
    setSubFormsSubmittableState((subForms) => ({
      ...subForms,
      products: isValidPrices,
    }));
  };

  const handleOverridePriceOnChange = (name, { value }) => {
    let temp = [...selected];

    temp = temp.map((product) => {
      if (product.sku === name) {
        return {
          ...product,
          overridePrice: value,
          error: !(stringToDecimal(value) > 0.01 && stringToDecimal(value) <= 999999.99),
        };
      }

      return product;
    });
    modifyField("dryStocks", {
      value: temp,
    });
    validateOverridePrices(temp);
  };

  const handleProductEntries = useCallback(
    (products) => {
      selectProductModal.close();
      setSelected((selected) => {
        if (products.length === 0) {
          modifyField("dryStocks", { value: [] });
          return [];
        }

        let temp = [...products];

        temp = temp.map((product) => ({
          ...product,
          overridePrice:
            find(selected, (o) => o.sku === product.sku)?.overridePrice || product?.amount,
        }));

        modifyField("dryStocks", { value: temp });
        return temp;
      });
    },
    [modifyField, selectProductModal]
  );

  const showProductSelection = useCallback(() => {
    selectProductModal.show({
      title: locale.selectProducts,
      primary: {
        text: selected.length > 0 ? locale.save : locale.add,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectProductModal]);

  return (
    <div className={styles.container}>
      {allProducts.length > 0 && (
        <SelectProductsModal
          {...selectProductModal}
          value={selected?.map((product) => product?.sku)}
          onChange={handleProductEntries}
          allProducts={allProducts}
          fromStation={true}
          coloredProduct={false}
          excludeProductType={["fuel"]}
        />
      )}
      {selected.length > 0 && (
        <ViewDryStocksComponent
          isView={formMode === FormMode.View}
          dryStocks={selected}
          handleOverridePriceOnChange={handleOverridePriceOnChange}
        />
      )}
      <RenderElementByFormMode>
        <Button
          onClick={showProductSelection}
          variant="outlined"
          className={styles.button}
          startIcon={<CheckIcon />}
          disabled={allProducts.length === 0}
          loading={allProducts.length === 0}
        >
          {selected.length > 0 ? locale.editProduct : locale.selectProduct}
        </Button>
      </RenderElementByFormMode>
    </div>
  );
};

export default InputDryStocksComponent;
