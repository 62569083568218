import Validation from "utils/validation.utils";
export const SHORT_TEXT_MAX_LEN = 75;
export const MEDIUM_TEXT_MAX_LEN = 150;
export const LONG_TEXT_MAX_LEN = 225;

export const initialStateProduct = (productDetails) => {
  return {
    type: {
      value: productDetails?.type || "",
      name: "type",
      required: true,
      validations: [Validation.required()],
    },
    image: {
      value: productDetails?.image || null,
      name: "image",
      required: true,
      validations: [Validation.required()],
    },
    name: {
      value: productDetails?.name || "",
      name: "name",
      maxLength: SHORT_TEXT_MAX_LEN,
      required: true,
      validations: [Validation.required()],
    },
    sku: {
      value: productDetails?.sku || "",
      name: "sku",
      maxLength: SHORT_TEXT_MAX_LEN,
      required: true,
      validations: [Validation.required()],
    },
    businessLocations: {
      value: productDetails?.productStations?.map(({ stationCode }) => stationCode) || [],
      name: "businessLocations",
      required: true,
      validations: [Validation.requiredArray({ notEmpty: true })],
    },

    // Lubricants and LPG only
    category: {
      value: productDetails?.categoryId ? `${productDetails?.categoryId}` : "",
      name: "category",
      validations: [],
    },
    variant: {
      value: productDetails?.variant || "",
      name: "variant",
      maxLength: SHORT_TEXT_MAX_LEN,
      validations: [],
    },
    defaultPrice: {
      value: productDetails?.amount || "",
      name: "defaultPrice",
      isPriceValue: true,
      innerLabel: true,
      validations: [],
      max: 999999.99,
      min: 0,
    },
  };
};
