import React, { useEffect } from "react";
import locale from "localization/en";
import usePromos from "hooks/usePromos";
import { useModal } from "hooks";
import styles from "../../station-details.module.scss";
import CheckIcon from "@material-ui/icons/Check";
import { SelectPromosModal } from "components/modals";
import { useCallback, useState } from "react";
import { Button } from "components/commons";
import ViewPromosComponent from "../view-components/view-promos";
import { useFormContext } from "contexts/form-context";
import { RenderElementByFormMode } from "../../station-details.module";
import { FormMode, PromoStatusEnum } from "enums";

const PromosComponent = () => {
  const { modifyField, fields, formMode } = useFormContext();
  const { allPromos } = usePromos();
  const selectPromoModal = useModal();
  const [selectedPromos, setSelectedPromos] = useState([]);

  const handlePromoEntries = useCallback(
    (promos) => {
      selectPromoModal.close();
      setSelectedPromos(promos);
      modifyField("promos", { value: promos });
    },
    [modifyField, selectPromoModal]
  );

  const showPromoSelection = useCallback(() => {
    selectPromoModal.show({
      title: locale.selectPromos,
      primary: {
        text: selectedPromos.length > 0 ? locale.save : locale.add,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPromoModal]);

  useEffect(() => {
    const promos = fields.promos?.value || [];
    if (allPromos.length > 0 && promos.length > 0 && selectedPromos.length === 0) {
      setSelectedPromos(promos);
    }
  }, [allPromos, fields.promos, selectedPromos]);

  return (
    <div className={styles.container}>
      <SelectPromosModal
        {...selectPromoModal}
        value={selectedPromos.map(({ promoId }) => promoId)}
        onChange={handlePromoEntries}
        allPromos={allPromos}
        fromStation={true}
        excludeStatus={[PromoStatusEnum.Cancelled, PromoStatusEnum.Ended]}
      />
      {selectedPromos.length > 0 && (
        <ViewPromosComponent isView={formMode === FormMode.View} stationPromos={selectedPromos} />
      )}
      <RenderElementByFormMode>
        <Button
          startIcon={<CheckIcon />}
          loading={allPromos.length === 0}
          disabled={allPromos.length === 0}
          onClick={showPromoSelection}
          variant="outlined"
          className={styles.button}
        >
          {selectedPromos.length > 0 ? locale.editPromo : locale.selectPromo}
        </Button>
      </RenderElementByFormMode>
    </div>
  );
};

export default PromosComponent;
