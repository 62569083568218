/* eslint-disable array-callback-return */
import { Autocomplete, Button, Product, Text, Title } from "components/commons";
import locale from "localization";
import styles from "../promo-details.module.scss";
import { ProductCodeEnum } from "enums";
import { useCallback, useEffect, useMemo } from "react";
import { InputAmount } from "components/field";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Validation from "utils/validation.utils";
import { useDynamicFieldsForm, useModal } from "hooks";
import SelectProductsModal from "components/modals/select-products/select-products-modal";
import useProducts from "hooks/useProducts";
import { prettifyProductCode } from "utils/pretty.utils";
import { sortProductsBySku } from "./product-input";

const InputProductsV2 = (rootForm = { onChangeIsFormSubmittable: (isFormSubmittable) => 0 }) => {
  const { onChangeIsFormSubmittable } = rootForm;
  const { allProducts } = useProducts();

  const {
    fields,
    fieldsAsRows,
    addRows,
    removeRow,
    modifyField,
    modifyRow,
    getRowsValues,
    isFormSubmittable,
  } = useDynamicFieldsForm({ rowId: "product" });

  const selectProductModal = useModal();
  const amountFormat = "0.00";

  const props = useMemo(
    () => ({
      innerLabel: true,
      required: true,
      placeholder: amountFormat,
      max: 999999.99,
      min: 0.01,
      decimalPlaces: 2,
      maxLength: 9,
      isPriceValue: true,
      validations: [
        Validation.required(),
        Validation.maxlength(11),
        Validation.min(0.01, { isFormatted: true }),
      ],
    }),
    [amountFormat]
  );

  const productsOption = useMemo(() => {
    const option =
      allProducts.length > 0
        ? sortProductsBySku(allProducts)?.map(({ sku, name }) => ({
            label: name,
            value: sku,
          }))
        : [];

    // avoid duplicate entry
    const selected = getRowsValues().map(({ product }) => product);
    return option.filter(({ value }) => !selected.includes(value));
  }, [allProducts, getRowsValues]);

  const renderRow = ({ product, amount, variant, name }, index) => {
    const productValue = fields?.[product?.name].value;
    return (
      <div key={`${product?.name}-${index}`} className={styles.productInput}>
        <Autocomplete
          {...fields?.[product?.name]}
          options={sortProductsBySku(
            [
              ...productsOption,
              {
                label: name.value,
                value: productValue,
              },
            ],
            "label"
          )}
          startAdornment={
            prettifyProductCode(productValue) && (
              <Product
                grass={productValue === ProductCodeEnum.GAS91}
                salsa={productValue === ProductCodeEnum.GAS95}
                deepBlue={productValue === ProductCodeEnum.GAS97}
                cheddar={productValue === ProductCodeEnum.DIESEL}
                style={{ width: "20px" }}
              />
            )
          }
          placeholder={"Select One"}
          onChange={(name, { value }) => {
            const product = allProducts.find(({ sku }) => sku === value);
            modifyRow(index, {
              product: value,
              name: product?.name,
              variant: product?.variant,
            });
          }}
        />
        <Text>{fields?.[variant?.name]?.value || "--"}</Text>
        <InputAmount
          {...fields?.[amount?.name]}
          value={fields?.[amount?.name].value}
          onChange={modifyField}
          className={styles.amount}
        />
        <CloseIcon onClick={() => removeRow(index)} className={styles.close} />
      </div>
    );
  };

  const openSelectionProduct = useCallback(() => {
    selectProductModal.show({
      title: locale.selectProducts,
    });
  }, [selectProductModal]);

  const handleProductEntries = useCallback(
    (entries = []) => {
      const newRows = [];

      sortProductsBySku(entries).forEach(({ sku, name, type, variant }, index) => {
        const row = [
          {
            name: "product",
            value: sku,
            required: true,
            validations: [Validation.required()],
          },
          {
            name: "variant",
            value: variant,
            validations: [],
          },
          {
            name: "name",
            value: name,
            validations: [],
          },
          {
            name: "amount",
            value: "",
            ...props,
          },
        ];

        newRows.push(row);
      });

      selectProductModal.close();
      addRows(newRows);
    },
    [addRows, props, selectProductModal]
  );

  // Monitor changes
  useEffect(() => {
    rootForm.modifyField("product", { value: getRowsValues() });
    onChangeIsFormSubmittable?.(isFormSubmittable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRowsValues, isFormSubmittable]);

  useEffect(() => {
    rootForm.modifyField("product", {}, ({ value: initialValue }) => {
      if (initialValue?.length > 0) {
        const rows = sortProductsBySku(initialValue, "label").map(
          ({ sku, value, label, variant }) => [
            {
              name: "product",
              value: sku,
              required: true,
              validations: [Validation.required()],
            },
            {
              name: "variant",
              value: variant,
              validations: [],
            },
            {
              name: "name",
              value: label,
              validations: [],
            },
            {
              name: "amount",
              value: value,
              ...props,
            },
          ]
        );
        addRows(rows);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div>
      <SelectProductsModal
        {...selectProductModal}
        value={getRowsValues().map(({ product }) => product)}
        onChange={handleProductEntries}
        allProducts={allProducts}
        coloredProduct={false}
        required
      />
      <Title className={styles.subtitle}>{locale.product}</Title>
      <div className={styles.container}>
        <div>
          {getRowsValues().length > 0 && (
            <>
              <div className={styles.flexCol}>
                {
                  // formState > fields (useForm) > iterateFormFields
                  // Mutate formState if any changes
                }
                <div className={styles.productInput}>
                  <div>{locale.productName}</div>
                  <div>{locale.variant}</div>
                </div>
                {fieldsAsRows?.map((row, i) => renderRow(row, i))}
              </div>
              <br />
            </>
          )}

          <Button
            onClick={openSelectionProduct}
            startIcon={<AddIcon />}
            variant="outlined"
            className={styles.button}
          >
            {locale.addProduct}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InputProductsV2;
