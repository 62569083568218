import locale from "localization/en";

export const productColumn = [
  { key: "type", text: `${locale.productType} / ${locale.category}` },
  { key: "name", text: `${locale.productName} / ${locale.productCode}` },
  { key: "variant", text: locale.productVariant },
  { key: "amount", text: locale.defaultPrice, width: "250px" },
  // { key: "stations", text: locale.participatingStations },
  { key: "actions", text: locale.actions },
];
