import locale from "localization";
import Button from "../button/button";
import { useRef, useState } from "react";
import styles from "./select-file.module.scss";
import Image from "../image/image";
import Text from "../text/text";
import { isString } from "lodash";
import { StocqIcon } from "images";

const SelectFile = (props) => {
  const refFile = useRef(null);
  const [isPreview, setIsPreview] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [error, setError] = useState();
  const [urlValue, setUrlValue] = useState(isString(props?.value) ? props?.value : null);

  const accept = props?.type === "image" ? "image/*" : undefined;

  const extractDirectoryWithFile = (url) => {
    const match = url.match(/.+\/([^/]+\.[a-z]+)(?=\/|$)/i);
    return match ? match[1] : null;
  };

  const handleFileChange = (event) => {
    setError(null);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const validFileTypes =
        props?.type === "image" ? ["image/jpeg", "image/jpg", "image/png"] : [];
      if (validFileTypes?.length > 0 && !validFileTypes.includes(selectedFile.type)) {
        setError(locale.invalidFileType);
        return;
      }

      if (props?.type === "image") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new window.Image();
          img.onload = () => {
            if (img.width > 100 || img.height > 100) {
              setError(locale.invalidDimensions);
              return;
            }

            props?.onChange?.(props?.name, { value: selectedFile });
            setFileData({
              name: selectedFile.name,
              size: `${(selectedFile.size / 1024).toFixed(2)} KB`,
              preview: URL.createObjectURL(selectedFile),
            });
            setUrlValue(null);
          };
          img.onerror = () => {
            setError(locale.invalidImage);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setFileData({
          name: selectedFile.name,
          size: `${(selectedFile.size / 1024).toFixed(2)} KB`,
          preview: URL.createObjectURL(selectedFile),
        });
      }
    }
  };

  return (
    <div className={styles.content}>
      {!props?.isView && (
        <div>
          <input type="file" hidden ref={refFile} onChange={handleFileChange} accept={accept} />
          <Button
            onClick={() => refFile?.current?.click()}
            primary
            style={{
              backgroundColor: "#eae8ff",
              color: "#5349b7",
              fontWeight: "500",
            }}
          >
            {urlValue || fileData ? locale.replace : locale.browseYourFiles}
          </Button>
          {error && (
            <Text subtitle color={"red"} style={{ marginTop: "5px", width: "160px" }}>
              {error}
            </Text>
          )}
        </div>
      )}
      {urlValue || fileData ? (
        <>
          {isPreview && (
            <div className={styles.preview}>
              <Image
                onClick={() => {
                  setIsPreview(false);
                }}
                src={urlValue || fileData?.preview}
                alt={fileData?.name}
              />
            </div>
          )}
          <div className={styles.result}>
            <Image
              onClick={() => {
                setIsPreview(true);
              }}
              src={urlValue || fileData?.preview}
              alt={fileData?.name}
            />
            <div>
              <Text className={styles.fileName}>
                {fileData?.name || extractDirectoryWithFile(urlValue)}
              </Text>
              <Text className={styles.fileSize}>{fileData?.size || "0.00 KB"}</Text>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.result}>
          <Image src={StocqIcon} alt="No file selected" />
          <Text>{locale.noFileSelected}</Text>
        </div>
      )}
    </div>
  );
};

export default SelectFile;
