import ConfirmModal from "../confirm-modal/confirm-modal";
import SelectPromosFilter from "./select-promo-filter";
import { initialFilterState } from "./select-promo-filter-state";
import { promosColumn } from "./select-promo-columns";
import { mapDataToList, mapFilterToRequest } from "./select-promo-mapper";
import { getPromoListApi } from "apis";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import locale from "localization";
import styles from "./select-promo-modal.module.scss";
import { excludeFilterAutoComplete } from "utils";
import classNames from "classnames";
import { Loader } from "components/commons";
import { PromoStatusEnum } from "enums";
import useSelectableItemsModal from "hooks/useSelectableItems";

const SelectPromosModal = ({
  onFetch = (allPromos) => 0,
  allPromos = [],
  excludeStatus = [],
  value,
  coloredProduct,
  fromStation,
  onChange,
  ...modal
}) => {
  const dataTable = {
    api: {
      api: getPromoListApi,
      mapper: (param) => param,
    },
    filter: {
      initialState: {
        ...initialFilterState(),
        status: {
          isSelectedAll: !excludeStatus || excludeStatus?.length === 0,
          value: Object.values(PromoStatusEnum).filter((value) => !excludeStatus?.includes(value)),
        },
      },
      mapper: mapFilterToRequest,
    },
    table: {
      key: "promos",
      mapper: (promo, { onCheck, selected }) =>
        mapDataToList({
          promo,
          onCheck,
          selected,
        }),
      columns: promosColumn,
    },
  };

  const {
    selected,
    setSelected,
    handleOnCheckAll,
    setIsCheckAllState,
    isCheckAllState,
    getAllItems,
    dataTable: { table, filter, search },
  } = useSelectableItemsModal({
    active: modal?.active,
    uniqueKey: "promoId",
    allItems: allPromos,
    value: value,
    dataTable,
    onFetchAllItemMapFilterState: (filterState) => {
      return {
        ...filterState,
        status: excludeFilterAutoComplete({
          ...filterState.status,
          exclude: excludeStatus,
          allOptions: Object.values(PromoStatusEnum),
        }),
      };
    },
  });

  const promoSearchFilter = [locale.sku, locale.promoName].join(", ");
  const stationSearchFilter = locale.promoName;

  const onClickSave = () => {
    onChange?.(
      selected
        .map((id) => allPromos.find(({ promoId }) => promoId === id))
        .filter((promo) => !!promo)
    );
  };

  return (
    <ConfirmModal
      {...modal}
      className={classNames(styles.modal, {
        [`${styles.station}`]: fromStation,
        [`${styles.promos}`]: !fromStation,
      })}
      primary={{
        ...modal?.primary,
        text: modal?.primary?.text || locale.save,
        disabled: modal?.required
          ? selected.length === 0
          : value?.length === 0 && selected.length === 0,
        onClick: onClickSave,
      }}
      secondary={{ ...modal?.secondary, text: locale.cancel }}
    >
      <SelectPromosFilter
        {...filter}
        {...search}
        actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
        actionProps={{
          link: selected.length > 0,
          variant: "text",
          style: {
            opacity: selected.length === 0 && 0.5,
          },
        }}
        actionOnClick={() => {
          setSelected([]);
          setIsCheckAllState(false);
        }}
        // uniquePromoTypes={uniquePromoTypes}
        placeholder={fromStation ? stationSearchFilter : promoSearchFilter}
      />
      {getAllItems.loading ? (
        <Loader open />
      ) : (
        <DataTableV2
          {...table}
          onCheckAll={handleOnCheckAll}
          actionHeaderProps={{
            checked: isCheckAllState,
          }}
          size="small"
        />
      )}
    </ConfirmModal>
  );
};

export default SelectPromosModal;
