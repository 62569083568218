import React, { useEffect, useMemo, useState } from "react";
import locale from "localization/en";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import styles from "../../station-details.module.scss";
import { Button, Autocomplete, Text, TextField, Field, SearchFieldV2 } from "components/commons";
import { useApi, useForm, useMount } from "hooks";
import Validation from "utils/validation.utils";
import { useFormContext } from "contexts/form-context";
import { prettifyStationPosition } from "utils/pretty.utils";
import { StationPositionEnum } from "enums";
import { SHORT_TEXT_MAX_LEN } from "../../add-edit-station-form-state";
import { getUsers as getUsersApi } from "apis/profile.api";

const InputUsersComponentV2 = (props) => {
  const { fields: rootFields, modifyField: modifyFieldRootForm } = useFormContext();

  const { modifyField, fields, clearForm, isFormSubmittable, getFormValues, modifyForm } = useForm({
    initialState: useMemo(() => {
      return {
        username: {
          name: "username",
          value: "",
          required: true,
          maxLength: 150,
          validations: [],
        },
        name: {
          name: "name",
          value: "",
          required: true,
          maxLength: SHORT_TEXT_MAX_LEN,
          validations: [Validation.required()],
        },
        position: {
          name: "position",
          value: "",
          required: true,
          validations: [Validation.required()],
        },
        userId: {
          name: "userId",
          value: "",
          validations: [Validation.required()],
        },
      };
    }, []),
  });

  const addRow = () => {
    const newUser = getFormValues();
    modifyFieldRootForm("users", {}, ({ value }) => {
      return {
        value: [...value, newUser],
      };
    });
    clearForm();
    setErrorMessage("");
    setUsernameText("");
  };

  const onRemove = (i) => {
    modifyFieldRootForm("users", {}, ({ value }) => {
      if (value.length > 0) {
        delete value[i];
      }

      return {
        value: value?.filter((val) => !!val),
      };
    });
  };

  useMount(() => {
    setErrorMessage("");
  });

  const { request: getUsers, loading } = useApi({
    api: getUsersApi,
    params: {
      page: 1,
      perPage: 1000,
    },
    modalError: false,
    handleOwnError: true,
  });

  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [usernameText, setUsernameText] = useState("");

  const isFormValid = isFormSubmittable && !errorMessage;
  const usernameError = fields?.username?.error || !!errorMessage;
  const users = rootFields?.users?.value || [];

  const getValidateUsername = async (username) => {
    setUsernameText(username);

    if (!username) {
      modifyForm({
        userId: {
          value: "",
        },
        username: {
          value: "",
        },
        name: {
          value: "",
        },
      });
      return;
    }

    const isExist = users.some((user) => user.username === username);
    if (isExist) {
      return setErrorMessage(locale.duplicateUsername);
    }

    setErrorMessage("");

    if (options.some(({ label }) => label === username)) return;

    setOptions((opt) => {
      return opt.filter(({ label }) => !users.some((user) => user.username === label));
    });

    const res = await getUsers({
      username,
      profileType: "pos",
    });

    if (res?.users?.length > 0) {
      let resUsers = res.users;

      setOptions((opt) => {
        opt = opt.filter(({ value }) => !resUsers.some((user) => user.userId === value));

        const newOptions = [
          ...opt,
          ...resUsers.map(({ userId, username, firstName, lastName }) => ({
            value: userId,
            label: username,
            name: firstName + " " + lastName,
          })),
        ]
          .sort((a, b) => a.label.localeCompare(b.label))
          .filter(({ label }) => !users.some((user) => user.username === label));

        return newOptions;
      });
    } else {
      setErrorMessage(locale.userNotFound);
      const isExist = users.some((user) => user.username === username);
      if (isExist) {
        return setErrorMessage(locale.duplicateUsername);
      }
    }
  };

  useEffect(() => {
    if (usernameText) {
      const user = options.find((user) => user.label === usernameText);
      if (user) {
        modifyForm({
          userId: {
            value: user.value,
          },
          name: {
            value: user.name,
            disabled: !!user.name,
            inputDisabled: !!user.name,
          },
          position: {
            value: user?.role,
          },
        });
      } else {
        modifyForm({
          userId: {
            value: "",
          },
          name: {
            value: "",
            disabled: false,
            inputDisabled: false,
          },
          position: {
            value: "",
          },
        });
      }
    } else {
      setErrorMessage("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, usernameText]);

  return (
    <div className={styles.container}>
      <div className={styles.userForm}>
        <Field
          {...fields.username}
          error={fields.username?.error || errorMessage}
          message={fields.username?.message || errorMessage}
          label={locale.username}
        >
          <SearchFieldV2
            {...fields.username}
            onChange={async (name, { value }) => {
              modifyForm({
                username: {
                  value: value,
                },
              });

              await getValidateUsername(value || "");
            }}
            loading={loading ? "loading" : ""}
            options={options.map(({ label: userId }) => userId)}
            placeholder={locale.searchOrCreate}
            error={usernameError}
            onClear={() => {
              clearForm();
              setErrorMessage("");
              setUsernameText("");
            }}
          />
        </Field>

        <Field label={locale.name} style={{ paddingBottom: usernameError && "1.5em" }}>
          <TextField {...fields.name} onChange={modifyField} placeholder="Type here" />
        </Field>

        <Field label={locale.position} style={{ paddingBottom: usernameError && "1.5em" }}>
          <Autocomplete
            options={Object.values(StationPositionEnum).map((value) => ({
              label: prettifyStationPosition(value),
              value,
            }))}
            {...fields.position}
            placeholder={locale.selectOne}
            onChange={modifyField}
          />
        </Field>
        <Button
          variant="outlined"
          className={styles.button}
          startIcon={<AddIcon />}
          onClick={addRow}
          style={{ marginTop: !usernameError && "1.3em" }}
          disabled={!isFormValid}
        >
          {locale.add}
        </Button>
      </div>

      <br />
      {users.map((item, i) => (
        <div className={styles.userList} key={item.username + i}>
          <Text className={styles.values}>{item.username}</Text>
          <Text className={styles.values}>{item.name}</Text>
          <Text className={styles.values}>{prettifyStationPosition(item.position)}</Text>
          <div className={styles.circleIcon} onClick={() => onRemove(i)}>
            <CloseIcon style={{ fontSize: "14px" }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default InputUsersComponentV2;
