import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getProductsApi = async (query) => {
  const res = await Get(`${ApiPath.Product}`, query);
  return res.data;
};

export const getProductByIdApi = async (query) => {
  const res = await Get(`${ApiPath.Product}/${query?.id}`, query);
  return res.data;
};

export const createProductApi = async ({ body }) => {
  const res = await Post(`${ApiPath.Product}`, body);
  return res.data;
};

export const updateProductApi = async (query) => {
  const res = await Put(`${ApiPath.Product}/${query?.id}`, query?.body);
  return res.data;
};

export const getDryStocksByStationCodeApi = async (query) => {
  const res = await Get(`${ApiPath.Stock}/dry-stock/admin`, query);
  return res.data;
};
