import PromoDetailsInputs from "./add-edit-components/promo-details-input";
import ParticipatingStationsInput from "./add-edit-components/participating-stations-input";
import { useMount, useRouter } from "hooks";
import { FormMode, Path, PromoTypeEnum } from "enums";
import { Button } from "components/commons";
import locale from "localization";
import styles from "./promo-details.module.scss";
import InputProductsV2 from "./add-edit-components/product-input-v2";

export const modalStyle = {
  titleStyle: styles.textNight,
  contentStyle: styles.textNight,
};

const AddEditPromoForm = ({
  addEditPromoForm,
  handleSave,
  isFormSubmittable,
  isSaving,
  onChangeIsFormSubmittable,
}) => {
  const router = useRouter();
  const pageMode = router.query?.mode || FormMode.Add;
  const { fields } = addEditPromoForm;
  const promoType = fields.promoType.value;

  useMount(async () => {
    if (pageMode === FormMode.Add && router.pathname !== Path.AddPromo) {
      return router.history.goBack();
    }
  });

  return (
    <>
      <PromoDetailsInputs {...addEditPromoForm} />
      {promoType === PromoTypeEnum.DiscountPerUnit && (
        <InputProductsV2
          {...addEditPromoForm}
          onChangeIsFormSubmittable={onChangeIsFormSubmittable}
        />
      )}
      <br />
      <ParticipatingStationsInput
        {...addEditPromoForm?.fields?.participatingStations}
        onChange={addEditPromoForm?.modifyField}
      />
      <br />
      <br />
      {pageMode === FormMode.Add && (
        <Button primary disabled={!isFormSubmittable} loading={isSaving} onClick={handleSave}>
          {locale.create} {locale.promo}
        </Button>
      )}
    </>
  );
};

export default AddEditPromoForm;
