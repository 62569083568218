import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { Path } from "enums";
import { useRouter } from "hooks";
import locale from "localization";
import ProductListFilter from "./product-list-filter";
import useDataTable from "hooks/useDataTable";
import { getProductsApi } from "apis";
import { initialFilterState } from "./product-filter.state";
import { mapDataToList, mapFilterToRequest } from "./product-list.mapper";
import { productColumn } from "./product-list-column";

const ProductsListModule = () => {
  const router = useRouter();

  const { filter, search, table } = useDataTable({
    api: {
      api: getProductsApi,
      params: {
        page: 1,
        perPage: 10,
        orderBy: "createdAt",
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "products",
      mapper: (products) => mapDataToList({ products, router }),
      columns: productColumn,
    },
  });

  return (
    <>
      <Intro
        title={locale.products}
        actionText={locale.addProduct}
        actionOnClick={() => router.push(Path.AddProduct)}
      />
      <ProductListFilter {...filter} {...search} />
      <DataTableV2 {...table} paginationV2 />
    </>
  );
};

export default ProductsListModule;
