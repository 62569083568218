import { useMemo } from "react";
import ConfirmModal from "../confirm-modal/confirm-modal";
import SelectProductsFilter from "./select-products-filter";
import { initialFilterState } from "./select-products-filter-state";
import { productsColumn, stationProductsColumn } from "./select-products-columns";
import { mapDataToList, mapFilterToRequest } from "./select-products-mapper";
import { getProductsApi } from "apis";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import locale from "localization";
import styles from "./select-products-modal.module.scss";
import { prettifyProductType } from "utils/pretty.utils";
import classNames from "classnames";
import useSelectableItemsModal from "hooks/useSelectableItems";
import { excludeFilterAutoComplete } from "utils";
import { sortProductsBySku } from "modules/promos/promo-details/add-edit-components/product-input";

const SelectProductsModal = ({
  onFetch = (allProducts) => 0,
  allProducts = [],
  fromStation = false,
  coloredProduct = true,
  excludeProductType = [],
  value,
  onChange,
  ...modal
}) => {
  const uniqueProductTypes = useMemo(() => {
    const all = allProducts || [];
    const options = all.reduce((acc, { category, type }) => {
      if (!acc.some((p) => p.value === type)) {
        acc.push({ label: prettifyProductType(type), value: type });
      }
      return acc;
    }, []);

    return {
      options,
      filtered: options.filter(({ value }) => !excludeProductType?.includes(value)),
    };
  }, [allProducts, excludeProductType]);

  const dataTable = {
    api: {
      api: getProductsApi,
      mapper: (param) => param,
    },
    filter: {
      initialState: {
        ...initialFilterState(),
        productType: {
          isSelectedAll: !excludeProductType || excludeProductType?.length === 0,
          value: uniqueProductTypes.filtered.map(({ value }) => value),
        },
      },
      mapper: mapFilterToRequest,
    },
    table: {
      key: "products",
      mapper: (product, { onCheck, selected }) =>
        mapDataToList({
          product,
          selected: selected,
          coloredProduct: coloredProduct,
          onCheck: onCheck,
        }),
      columns: fromStation ? stationProductsColumn : productsColumn,
    },
  };

  const {
    selected,
    setSelected,
    handleOnCheckAll,
    setIsCheckAllState,
    isCheckAllState,
    dataTable: { table, filter, search },
  } = useSelectableItemsModal({
    active: modal?.active,
    uniqueKey: "sku",
    allItems: allProducts,
    value: value,
    dataTable,
    onFetchAllItemMapFilterState: (filterState) => {
      return {
        ...filterState,
        productType: excludeFilterAutoComplete({
          ...filterState.productType,
          exclude: excludeProductType,
          allOptions: uniqueProductTypes.options,
        }),
      };
    },
  });

  const promoSearchFilter = [locale.sku, locale.productName].join(", ");
  const stationSearchFilter = locale.productName;

  const onClickSave = () => {
    onChange?.(
      selected
        .map((name) => allProducts.find(({ sku }) => sku === name))
        .filter((product) => !!product)
    );
  };

  return (
    <ConfirmModal
      {...modal}
      className={classNames(styles.modal, {
        [`${styles.station}`]: fromStation,
        [`${styles.promos}`]: !fromStation,
      })}
      primary={{
        ...modal?.primary,
        text: modal?.primary?.text || locale.save,
        disabled: modal?.required
          ? selected.length === 0
          : value?.length === 0 && selected.length === 0,
        onClick: onClickSave,
      }}
      secondary={{ ...modal?.secondary, text: locale.cancel }}
    >
      <SelectProductsFilter
        {...filter}
        {...search}
        actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
        actionProps={{
          link: selected.length > 0,
          variant: "text",
          style: {
            opacity: selected.length === 0 && 0.5,
          },
        }}
        actionOnClick={() => {
          setSelected([]);
          setIsCheckAllState(false);
        }}
        uniqueProductTypes={uniqueProductTypes.filtered}
        placeholder={fromStation ? stationSearchFilter : promoSearchFilter}
      />
      <DataTableV2
        {...table}
        data={sortProductsBySku(table.data, "productName")}
        onCheckAll={handleOnCheckAll}
        actionHeaderProps={{
          checked: isCheckAllState,
        }}
        size="small"
      />
    </ConfirmModal>
  );
};

export default SelectProductsModal;
