import { useEffect } from "react";

/**
 * A hook that runs one time only after render
 */
const useMountAsync = (callback, dependencies = []) => {
  useEffect(() => {
    const abortController = new AbortController();

    const executeCallback = async () => {
      try {
        await callback(abortController.signal);
      } catch (error) {
        // Ignore errors caused by aborting
        if (error.name !== "AbortError") {
          console.error("Error in useMount callback:", error);
        }
      }
    };

    executeCallback();

    return () => {
      abortController.abort(); // Cancel any ongoing async tasks
    };
    // eslint-disable-next-line
  }, [...dependencies]);
};

export default useMountAsync;
