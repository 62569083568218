import { useState, useEffect, useCallback } from "react";
import { useApi } from "hooks";
import useDataTable from "./useDataTable";
import { handleRequest } from "utils";

// Custom hook for handling selectable items using modal
const useSelectableItemsModal = ({
  active,
  uniqueKey,
  allItems = [],
  itemStatusEnum = {},
  onFetch = () => {},
  value = [],
  isCheckAll = false,
  onFetchAllItemMapFilterState = (filterState) => filterState,
  dataTable: { api: dataTableApi, table, filter, search },
}) => {
  const [selected, setSelected] = useState(value);
  const [allItemsState, setAllItemsState] = useState(allItems);
  const [loading, setLoading] = useState(false);
  const [isCheckAllState, setIsCheckAllState] = useState(isCheckAll); // Manage isCheckAll state

  const onCheck = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
    );
  };

  const handleOnCheckAll = () => {
    setIsCheckAllState((prev) => !prev); // Toggle the isCheckAllState
    setSelected((prevSelected) => {
      const filteredResult = allItemsState.map((item) => item?.[uniqueKey]); // Adjust based on uniqueKey (sku or promoId)
      let result = [];
      if (isCheckAllState) {
        result = prevSelected.filter((item) => !filteredResult.includes(item));
      } else {
        result = [...new Set([...prevSelected, ...filteredResult])];
      }
      return result;
    });
  };

  const dataTable = useDataTable({
    api: dataTableApi,
    filter: filter,
    table: {
      ...table,
      mapper: (item) =>
        table?.mapper(item, {
          onCheck,
          selected,
          isCheckAllState,
          setIsCheckAllState,
          allItemsState,
        }),
    },
  });

  const { mapper: mapFilterToRequest, initialState: initialFilterState } = filter;

  const getAllItems = useApi({ api: dataTableApi?.api });

  const fetchAllItems = useCallback(
    async (filterState) => {
      setLoading(true);
      try {
        const res = await getAllItems?.request({
          ...mapFilterToRequest({
            ...(onFetchAllItemMapFilterState?.(filterState) || filterState),
          }),
          perPage: 1000,
        });

        setLoading(false);
        const fetchedItems = res?.[table?.key];

        if (isCheckAllState) {
          setSelected((itemIds) => {
            return [...new Set([...itemIds, ...fetchedItems?.map((item) => item?.[uniqueKey])])];
          }); // Adjust based on uniqueKey (sku or promoId)
        }
        return fetchedItems;
      } catch (error) {
        setLoading(false);
        // Handle error accordingly
        console.error(error);
      }
    },
    [
      getAllItems,
      isCheckAllState,
      uniqueKey,
      mapFilterToRequest,
      onFetchAllItemMapFilterState,
      table?.key,
    ] // Use isCheckAllState instead of isCheckAll
  );

  useEffect(() => {
    if (active) {
      handleRequest(async () => {
        if (allItems?.length > 0) {
          setAllItemsState(allItems);
        } else {
          const allItemsRes = await fetchAllItems(initialFilterState);
          setAllItemsState(allItemsRes || []);
          onFetch(allItemsRes);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems, active]);

  useEffect(() => {
    if (active && value && value?.length >= 0) {
      setAllItemsState(allItems);
      setSelected(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, active, allItems]);

  useEffect(() => {
    if (active) {
      setIsCheckAllState(false);
      dataTable.search?.modifySearch("");
      dataTable.filter.applyClearFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const applyClearSearch = async () => {
    setAllItemsState(
      await fetchAllItems({
        ...dataTable.filter.filterState,
        searchKey: "",
        perPage: 1000,
        id: "applyClearSearch",
      })
    );
    dataTable.search.applyClearSearch();
  };

  const applyFilter = async () => {
    setAllItemsState(
      await fetchAllItems({
        ...dataTable.filter.filterState,
        searchKey: "",
        perPage: 1000,
        id: "all",
      })
    );
    dataTable.filter?.applyFilter();
  };

  const applySearch = async () => {
    setAllItemsState(
      await fetchAllItems({
        ...dataTable.filter.filterState,
        searchKey: dataTable.search.searchKey,
        perPage: 1000,
        id: "all",
      })
    );

    dataTable.search.applySearch();
  };

  const applyClearFilter = async () => {
    setAllItemsState(
      await fetchAllItems({
        ...initialFilterState,
        searchKey: "",
        perPage: 1000,
        id: "all",
      })
    );

    dataTable.search.modifySearch("");
    dataTable.filter.applyClearFilter();
  };

  return {
    allItemsState,
    selected,
    setSelected,
    loading,
    fetchAllItems,
    handleOnCheckAll,
    onCheck,
    setIsCheckAllState,
    isCheckAllState,
    getAllItems,
    dataTable: {
      ...dataTable,
      filter: {
        ...dataTable.filter,
        applyClearFilter,
        applyFilter,
      },
      search: {
        ...dataTable.search,
        applySearch,
        applyClearSearch,
      },
    },
  };
};

export default useSelectableItemsModal;
