import React, { useEffect, useMemo, useState } from "react";
import { BackLink, Button, Chip, Intro, Text, TimePickerRange } from "components/commons";
import { FormMode, Path, PromoStatusEnum } from "enums";
import locale from "localization";
import { useApi, useModal, useRouter } from "hooks";
import usePromos from "hooks/usePromos";
import initialStationFormState from "./add-edit-station-form-state";
import { FormProvider, useFormContext } from "contexts/form-context";
import useProducts from "hooks/useProducts";
import AddEditStationForm from "./add-edit-station-form";
import { formatAmount, formatNumber, handleRequest } from "utils";
import {
  createStationApi,
  getDryStocksByStationCodeApi,
  getPromosByStationCodeApi,
  getStationApi,
  updateStationStatus,
} from "apis";
import { CloudOff, EditOutlined, CloudQueueOutlined } from "@material-ui/icons";
import AppLoader from "components/commons/app-loader/app-loader";
import { getStationUsersApi } from "apis/profile.api";
import { ConfirmModal } from "components/modals";
import { stringToDecimal } from "utils/text.utils";
import moment from "moment";
import { modalStyle } from "modules/promos/promo-details/add-edit-promo-form";
import { Box } from "@material-ui/core";
import { prettifyStationStatus } from "utils/pretty.utils";
import StationStatusEnum from "enums/station-status";
import useLeavePageBlocker from "hooks/useLeavePageBlocker";
import styles from "./station-details.module.scss";
import { isArray } from "lodash";

export const RenderInput = ({ Component, prettify, ...props }) => {
  const { formMode } = useFormContext();

  if (Component.name !== TimePickerRange.name && formMode === FormMode.View) {
    if (props?.isPriceValue && props?.decimalPlaces) {
      return <Text color={"#000"}>₱ {formatNumber(props?.value, props.decimalPlaces)}</Text>;
    }

    return (
      <Text color={"#000"}>
        {props?.isPriceValue
          ? formatAmount(props.value)
          : prettify
          ? prettify?.(props.value)
          : isArray(props.value)
          ? props.value.join(", ")
          : props.value}
      </Text>
    );
  }

  return <Component {...props} />;
};

export const RenderElementByFormMode = ({ children, mode }) => {
  const { formMode } = useFormContext();

  if (mode) {
    if (formMode === mode) {
      return children;
    }
    return <></>;
  }

  if (formMode !== FormMode.View) {
    return children;
  }

  return <></>;
};

const StationDetailsModule = () => {
  const [form, setForm] = useState(null);

  const [isLeavePageBlock, setIsLeavePageBlock] = useState(true);

  const { fetch: getAllPromos } = usePromos({ key: "station-all-promos" });
  const { fetch: getAllProducts } = useProducts({ key: "station-all-products" });

  const leaveModal = useModal();

  const router = useRouter();
  const pageMode = router.query?.mode || FormMode.Add;
  const stationId = router.query?.id;

  let pageTitle;

  const {
    loading,
    result: stationDetails,
    request: getStation,
  } = useApi({
    api: getStationApi,
    key: "station-details",
  });

  const {
    request: getStationDryStocks,
    result: dryStocks,
    loading: isLoadingStationDryStocks,
  } = useApi({
    api: getDryStocksByStationCodeApi,
    params: {
      page: 1,
      perPage: 1000,
    },
    key: "station-dry-stocks",
  });

  const {
    request: getStationUsers,
    result: users,
    loading: isLoadingStationUsers,
  } = useApi({
    api: getStationUsersApi,
    params: {
      page: 1,
      perPage: 1000,
    },
    key: "station-users",
  });

  const {
    request: getStationPromos,
    result: stationPromos,
    loading: isLoadingStationPromos,
  } = useApi({
    api: getPromosByStationCodeApi,
    key: "station-promos",
    params: {
      page: 1,
      perPage: 1000,
      status: [PromoStatusEnum.Ongoing, PromoStatusEnum.Upcoming].join(","),
    },
  });

  const isLoading = isLoadingStationDryStocks || isLoadingStationUsers || isLoadingStationPromos;

  const isAdding = pageMode === FormMode.Add;

  const successModal = useModal();

  const createStation = useApi({
    api: createStationApi,
    key: "create-station",
    modalError: false,
  });

  const { request: updateStation, loading: isUpdating } = useApi({
    api: updateStationStatus,
  });

  const handleOnSubmit = async (formData) => {
    const payload = formData;

    const body = {
      registeredBusinessName: payload.taxPayerName,
      stationCode: Number(payload.code),
      name: payload.name,
      businessAddress: payload.taxPayerRegisteredAddress,
      type: payload.type,
      subscription: payload.subscription,
      vatRegTin: payload.birTin,
      serialNumber: payload.serialNumber,
      machineIdentificationNumber: payload.machineIdentificationNo,
      pumpBrands: isArray(payload.pumpBrands)
        ? payload.pumpBrands?.map(({ value }) => value)?.join(",")
        : payload.pumpBrands,
      thresholdSafeDrop: stringToDecimal(payload.safeDropThreshold),
      maxTransactionStacking: stringToDecimal(payload.maxTransactionStacking),
      ptuNumber: payload.ptuNumber,
      opensAt: moment(payload.openingTime).format("HH:mm:ss"),
      closesAt: moment(payload.closingTime).format("HH:mm:ss"),
      latitude: +payload.lat,
      longitude: +payload.long,
      users: payload.users.map((user) => ({
        userId: user.userId,
        username: user.username,
        password: "password123!",
        // firstName: user.firstName,
        // lastName: user.lastName,
        name: user.name,
        role: user.position,
      })),
      products: payload.dryStocks.map(({ sku, overridePrice }) => ({
        sku: sku,
        amount: stringToDecimal(overridePrice),
      })),
      promos: payload.promos.map((promo) => promo.promoId),
      stationUgt: payload.ugts.map((ugt) => ({
        productCode: ugt.product,
        liter: stringToDecimal(ugt.liters),
      })),
      // keyAccounts: payload.keyAccounts.map(({ keyAccount, paymentMethod, products }) => ({
      //     name: keyAccount,
      //     paymentMethod,
      //     code: "random123!"
      // }))
    };

    successModal.show({
      title: `${isAdding ? locale.addStation : locale.saveChanges}?`,
      content: isAdding ? (
        <locale.Populate
          text={locale.onBoardStation}
          items={[
            <>
              <br />
              <b>{body.name}</b>
            </>,
          ]}
        />
      ) : (
        locale.confirmChanges
      ),
      primary: {
        text: isAdding ? locale.addStation : locale.saveChanges,
        onClick: async () => {
          await handleSaving();
        },
      },
      secondary: {
        text: locale.continueEditing,
      },
    });

    const handleSaving = async () => {
      try {
        const req = isAdding
          ? await createStation.request(body)
          : await updateStation({
              stationId: stationId,
              query: {
                ...body,
              },
            });

        console.log({
          req,
        });

        if (!isAdding) {
          setIsLeavePageBlock(false);
        }

        successModal.show({
          title: `${locale.success}!`,
          content: isAdding ? locale.successStation : locale.changesSuccess,
          primary: {
            onClick: () => {
              router.push(Path.Stations);
            },
          },
          close: () => {
            router.push(Path.Stations);
          },
        });
      } catch (error) {
        successModal.close();
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Use 'auto' for instant scroll
        });
      }
    };
  };

  switch (pageMode) {
    case FormMode.Add:
      pageTitle = locale.addStation;
      break;
    case FormMode.Edit:
      pageTitle = locale.editStation;
      break;
    default:
      pageTitle = "Station Name Here";
  }

  const initialState = useMemo(() => {
    const formData = initialStationFormState({
      stationDetails,
    });
    return formData;
  }, [stationDetails]);

  const actionText = useMemo(() => {
    if (!form) return null;
    if (pageMode === FormMode.View) {
      return {
        children: (
          <>
            <EditOutlined fontSize="inherit" style={{ marginRight: "10px" }} /> {locale.edit}
          </>
        ),
        onClick: () => router.push(`${Path.Stations}/details/${stationId}/edit`),
      };
    }
    if (pageMode === FormMode.Edit) {
      return {
        disabled: !form?.isFormSubmittable || isUpdating,
        children: locale.saveChanges,
        loading: isUpdating || isLoading,
        onClick: () => {
          handleOnSubmit(form.getFormValues());
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageMode, stationId, form, isUpdating, isLoading]);

  const Icon = stationDetails?.isConnectedToPts ? CloudQueueOutlined : CloudOff;

  useEffect(() => {
    handleRequest(async () => {
      if (stationId) {
        const station = await getStation({
          stationId,
        });

        if (station?.stationCode) {
          await Promise.all([
            getStationUsers({
              stationId,
            }),
            getStationDryStocks({
              stationCode: station?.stationCode,
              // stationCode: 9999, // for testing
            }),
            getStationPromos({
              stationCode: station?.stationCode,
            }),
          ]);
        }
      }

      await Promise.all([
        getAllProducts({
          filterResult: (products) => {
            return products.filter(({ type }) => type !== "fuel");
          },
        }),
        getAllPromos({
          status: [PromoStatusEnum.Ongoing, PromoStatusEnum.Upcoming].join(","),
        }),
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  useLeavePageBlocker(
    (nextPathname) => {
      if (pageMode === FormMode.Edit) {
        leaveModal.show({
          title: locale.leavePageQuestion,
          content: locale.leavePageQuestionContent,
          secondary: {
            text: locale.continueEditing,
          },
          primary: {
            text: locale.leavePage,
            onClick: () => {
              window.location.href = nextPathname;
            },
          },
          ...modalStyle,
          contentStyle: styles.contentLeavePageModal,
        });
      }
    },
    [],
    { isListen: pageMode === FormMode.Edit && isLeavePageBlock }
  );

  return (
    <FormProvider initialState={initialState} onChange={(form) => setForm(form)}>
      <ConfirmModal
        {...successModal}
        primary={{ ...successModal.primary, disabled: createStation.loading || isUpdating }}
        loading={createStation.loading || isUpdating}
        closable={!createStation.loading || !isUpdating}
        {...modalStyle}
      />
      <ConfirmModal {...leaveModal} />
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <BackLink text={locale.stations} path={Path.Stations} />
        </Box>
        <Box>{actionText && <Button color={"primary"} variant="contained" {...actionText} />}</Box>
      </Box>
      <Intro title={pageMode === FormMode.View ? stationDetails?.name : pageTitle} />
      {pageMode === FormMode.View && (
        <>
          <div style={{ display: "flex", gap: "1em" }}>
            <Chip
              label={prettifyStationStatus(stationDetails?.status)}
              status={
                {
                  [StationStatusEnum.ACTIVE]: "success",
                  [StationStatusEnum.PENDING]: "primary",
                  [StationStatusEnum.DEACTIVATED]: "default",
                  [StationStatusEnum.MANUAL_MODE]: "warning",
                }[stationDetails?.status]
              }
              // status={stationDetails?.status === StationStatusEnum.ACTIVE ? "success" : "default"}
              style={{ padding: "0px 5px" }}
            />
            <Chip
              icon={<Icon fontSize="small" />}
              status={stationDetails?.isConnectedToPts ? "success" : "error"}
              chipSize={30}
              style={{
                backgroundColor: stationDetails?.isConnectedToPts && "#8fdb85",
              }}
            />
          </div>
          <br />
          <br />
        </>
      )}
      {loading ? (
        <AppLoader />
      ) : (
        <AddEditStationForm
          handleOnSubmit={handleOnSubmit}
          stationDetails={stationDetails}
          dryStocks={dryStocks?.dryStocks}
          users={users}
          promos={stationPromos?.promos}
          loading={createStation.loading || isUpdating}
        />
      )}
    </FormProvider>
  );
};

export default StationDetailsModule;
